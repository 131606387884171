<template>
  <v-row justify="center" align="center">
    <v-sheet
      :color="colors[color][subcolor]"
      height="100%"
      width="100%"
    >
      <v-btn-toggle
        v-model="color"
        tile
        group
      >
        <v-btn v-for="(cat, i ) in categories" :key="i" :value="cat.color" icon @click="update(i)">
          <v-icon :color="colors[cat.color][cat.subcolor]">{{cat.icon}}</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-sheet>
  </v-row>
</template>

<script>
import colors from 'vuetify/lib/util/colors'
import { mdiThumbUp, mdiClockTimeThreeOutline, mdiCancel, mdiRefresh } from '@mdi/js'
import httpClient from '@/services/http.service'

export default {
  name: 'SelectStatSale',
  props:{
    item:{
      type: Object,
      default: () => {}
    },
    strict: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    },
    product_id:{
      type: Number,
      default: 0
    }
  },
  data(){
    return{
      colors,
      color: 'shades',
      subcolor: 'transparent',
      categories: [{text: 'Взяли', color: 'green', subcolor: 'base', icon: mdiThumbUp}, {text: 'Подумаем', color: 'yellow', subcolor: 'base', icon: mdiClockTimeThreeOutline},
        {text: 'Отказ', color: 'red', subcolor: 'base', icon: mdiCancel}, {text: '', color: 'grey', subcolor: 'base', icon: mdiRefresh}],

    }
  },
  created() {
    if (this.strict){
      if (this.item.sale === 'Взяли') {this.color = 'green'; this.subcolor = 'base'}
      else if (this.item.sale === 'Подумаем') {this.color = 'yellow'; this.subcolor = 'base'}
      else if (this.item.sale === 'Отказ') {this.color = 'red'; this.subcolor = 'base'}
      else {this.color = 'shades'; this.subcolor='transparent'}
    }else{
      if (this.item.template_sale === 'Взяли') {this.color = 'green'; this.subcolor = 'base'}
      else if (this.item.template_sale === 'Подумаем') {this.color = 'yellow'; this.subcolor = 'base'}
      else if (this.item.template_sale === 'Отказ') {this.color = 'red'; this.subcolor = 'base'}
      else {this.color = 'shades'; this.subcolor='transparent'}
    }
  },
  methods:{
    update(i){
      if (this.categories[i].color === 'grey'){
        this.color = 'shades'
        this.subcolor = 'transparent'
      } else{
        this.color = this.categories[i].color
        this.subcolor = this.categories[i].subcolor
      }
      if (this.strict){
        httpClient.post(`products/update-sale?strict=${this.strict}&is_sale=${this.categories[i].text}&id=${this.item.id}`)
      }else{
        httpClient.post(`products/update-sale?strict=${this.strict}&url=${this.item.ali_link}&is_sale=${this.categories[i].text}`)
      }
    }
  }
}
</script>

<style scoped>

</style>
