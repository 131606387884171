<template>
  <section id="productSearch">
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-title>Название продукта или URL</v-card-title>
          <v-card-text>
            <v-text-field v-model="search" outlined label="Поиск по ссылке или названию" placeholder="Поиск по ссылке или названию" :loading="searchLoading" autofocus></v-text-field>
            <v-menu
              ref="orderMenu"
              v-model="orderMenu"
              :close-on-content-click="false"
              :return-value.sync="orderMenu"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datePost"
                  label="Дата публикации поста"
                  :prepend-icon="mdiCalendar"
                  readonly
                  outlined
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  @change="debSearchProduct()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datePost"
                no-title
                scrollable
                range
                @change="debSearchProduct()"
              >
              </v-date-picker>
            </v-menu>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>Настроечки</v-card-title>
          <v-card-text>
            <v-select
              v-model="channel"
              :items="channels"
              item-value="id"
              item-text="name"
              outlined
              clearable
              label="Канал"
              placeholder="Канал"
              @change="debSearchProduct()"
            >
            </v-select>
            <v-select
              v-model="cats"
              label="Категории"
              placeholder="Категории"
              :items="categories"
              item-text="title"
              item-value="id"
              outlined
              clearable
              multiple
              @change="debSearchProduct()"
            >
            </v-select>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>Ещё настроечки</v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="productCats"
              :items="productCategories"
              item-value="id"
              item-text="name"
              outlined
              clearable
              label="Категории продукта"
              placeholder="Категории продукта"
              multiple
              @change="debSearchProduct()"
            >
            </v-autocomplete>
            <v-autocomplete
              v-model="tags"
              label="Теги"
              placeholder="Теги"
              :items="productTags"
              item-text="name"
              item-value="id"
              outlined
              multiple
              clearable
              @change="debSearchProduct()"
            >
            </v-autocomplete>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="products"
          item-key="name"
          class="elevation-1"
        >
          <template v-slot:item.ali_link="{ item }">
            <a :href="'/products/show/' + item.id" target="_blank">{{item.ali_link}}</a>
          </template>
          <template v-slot:item.index.index="{ item }">
            <v-chip>{{item.index.index.toFixed(2)}}</v-chip>
          </template>
          <template v-slot:item.postings="{ item }">
            <v-dialog
              :key="item.id"
              max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  color="success"
                  v-bind="attrs"
                  dark
                  v-on="on"
                >
                  {{ item.posts }}
                </v-chip>
              </template>
              <show-posts :init-posts="item.post_info.posts" :key="item.id" ref="showPosts" :strict-url="strictUrl" :url="item.ali_link" :channel_id="channel" :product_id="item.id" :search="search"></show-posts>
            </v-dialog>

          </template>
          <template v-slot:item.last_posted_at="{ item }">
            {{item.post_info.last_posted.length>0?formatDate(item.post_info.last_posted[0]):''}}
          </template>

          <template v-slot:item.comment="{ item }">
            <v-text-field v-model="item.comment" @change="updateComment(item)">
            </v-text-field>
          </template>
          <template v-slot:item.category="{ item, index }">
            <v-chip v-for="(cat, i) in item.statCats" :key="i" :color="colors[cat.class].base" class="ma-1" label close @click:close="removeCat(cat.id, i, index)">
              {{cat.title}}
            </v-chip>
            <select-stat-cat :prod_id="index" @addCat="addCat"></select-stat-cat>
          </template>
          <template v-slot:item.earnings="{ item }">
            <v-chip

              color="success"
              dark
            >
              {{item.earnings}}
            </v-chip>
          </template>
          <template v-slot:item.earningsPerPost="{ item }">
            <v-chip
              color="success"
              dark
            >
              {{ (item.earnings / item.posts).toFixed(2)}}
            </v-chip>
          </template>
          <template v-slot:item.is_sale="{ item }">
            <select-stat-sale :strict="strictUrl" :url="item.ali_link" :product_id="item.id" :item="item"></select-stat-sale>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
<!--    <v-row class="d-flex">-->
<!--      <v-col v-for="(product, i) in products" :key="i" cols="3">-->
<!--        <v-card>-->
<!--          <v-img-->
<!--            :src="'https://backend.telestatic.ru/upload/product_images/'+product.image"-->
<!--            height="400"-->
<!--          />-->
<!--          <v-card-title>{{product.template_title}}</v-card-title>-->
<!--          <v-card-text>-->
<!--            Публикаций: {{product.posts}} <br>-->
<!--            Последняя публикация: {{product.last_posted_at}}-->
<!--          </v-card-text>-->
<!--          <v-card-actions>-->
<!--            <v-btn color="primary" left @click="post(product)"><v-icon>{{mdiPost}}</v-icon> Запостить</v-btn>-->
<!--            <v-btn color="info" @click="view(product)"><v-icon>{{mdiArchiveSearch}}</v-icon> Просмотреть</v-btn>-->
<!--          </v-card-actions>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--    </v-row>-->
  </section>
</template>

<script>
import {mdiPost, mdiArchiveSearch, mdiEmailOpenOutline, mdiEye, mdiCalendar} from '@mdi/js'
import _ from 'lodash'
import QueryString from 'qs'
import moment from 'moment'
import colors from 'vuetify/lib/util/colors'
import httpClient from '@/services/http.service'
import SelectStatCat from '@/components/products/SelectStatCat'
import ShowPosts from '@/components/products/ShowPosts'
import SelectStatSale from '@/components/products/SelectStatSale'

export default {
  name: 'ProductsIndex',
  components:{
    SelectStatSale,
    SelectStatCat, ShowPosts
  },
  data(){
    return{
      colors,
      mdiPost, mdiArchiveSearch, mdiEmailOpenOutline, mdiEye, mdiCalendar,
      strictUrl: false, //флаг означающий что идёт поиск по URL - т.е. по "шаблону" ("шаблон" - это группа продуктов с одинаковым ali_link URL)
      orderMenu: false,
      searchLoading: false,
      search: null,
      datePost: null,
      cats: [],
      productCats: [],
      tags: [],
      products: [],
      headers: [
        {text: 'Заголовок',
          value: 'template_title',
        },
        {text: 'Ссылка',
          value: 'ali_link',
        },
        {text: 'Публикаций',
          value: 'postings',
        },
        {text: 'Индекс',
          value: 'index.index',
        },
        {text: 'Посл. публ.',
          value: 'last_posted_at',
        },
        {text: 'Категория',
          value: 'category',
        },
        {text: 'Комментарий',
          value: 'comment',
        },
        {text: 'Распродажа',
          value: 'is_sale',
        },
        {text: 'Доход общий',
          value: 'earnings',
        },
        {text: 'Доход на публикацию',
          value: 'earningsPerPost',
        },
        // {text: 'Действия',
        //   value: 'actions',
        //   sortable: false
        // },
      ],
      channel: 5,
      showDialogPosts: false
    }
  },
  computed:{
    channels(){
      return JSON.parse(JSON.stringify(this.$store.state.channels))
    },
    categories(){
      return JSON.parse(JSON.stringify(this.$store.state.productStatCats))
    },
    productCategories(){
      return JSON.parse(JSON.stringify(this.$store.state.productCats))
    },
    productTags(){
      return JSON.parse(JSON.stringify(this.$store.state.productTags))
    }
  },
  watch:{
    search(v){
      if (v){
        this.debSearchProduct()
      }
    },
  },
  methods:{
    debSearchProduct:_.debounce(function () {
      this.searchProduct()
    }, 500),
    searchProduct(){
      const v = this.search
      try {
        const url = new URL(v)
        this.strictUrl = true
      }
      catch (error) {
        this.strictUrl = false
      }

      if (!this.searchLoading && this.search){
        this.searchLoading = true
        let url = `products/search?search=${v}&group_url=true&channel_id=${this.channel}`
        if (this.datePost !== null){
          url += `&datePost[0]=${this.datePost[0]}&datePost[1]=${this.datePost[1]}`
        }
        if (this.cats.length>0){
          _.each(this.cats, (cat, i)=>{
            url+= `&categories[${i}]=${cat}`
          })
        }
        if (this.productCats.length > 0){
          _.each(this.productCats, (cat, i)=>{
            url += `&productCats[${i}]=${cat}`
          })
        }
        if (this.tags.length>0){
          _.each(this.tags, (tag, i)=>{
            url += `&tags[${i}]=${tag}`
          })
        }
        httpClient.get(url)
          .then(({data})=>{
            this.products = data
            this.searchLoading = false
          })
          .catch(({ message }) => {
            this.products = []
            this.searchLoading = false
            console.log(message)
          })
      }
    },
    prettifyChars(chars){
      let str = `<ul><li>${chars}`
      str = str.replaceAll('\n', '</li><li>')
      str += '</ul>'
      return str
    },
    view(product){
      this.$store.dispatch('setShowProduct', {show: true, product})
    },
    updateComment(item){
      httpClient.post('products/update-comment', QueryString.stringify({url: item.ali_link, comment: item.comment}))
    },
    formatDate(date){
      return moment(date).format('DD.MM.YY H:mm')
    },
    removeCat(id, i, j){
      console.log('id, i, j: ', id, i, j)
      this.products[j].statCats.splice(i, 1)
      httpClient.post(`products/remove-cat?cat_id=${id}&url=${this.products[j].ali_link}`)
    },
    addCat(prod_id, cat_id){
      this.products[prod_id].statCats.push(this.categories.find(x=>x.id===cat_id))
      const url = this.products[prod_id].ali_link
      httpClient.post(`products/add-cat?cat_id=${cat_id}&url=${url}`)
    }
  }
}
</script>

<style scoped>

</style>
