<template v-if="channels">
  <v-card>
    <v-card-text>
      <v-data-table
        :items="posts"
        :headers="headers"
        item-key="name"
        class="elevation-1"
        :loading="loading"
      >
        <template v-if="fullView" v-slot:item.channel_id="{ item }">
          <div @click="showTgMessage()">
            {{getChannelName(item.channel_id)}}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      width="600"
    >
      <vue-telegram-embed link="naydema/13886" width="100%"></vue-telegram-embed>
      <div id="tgMessage" ref="tgMessage">
      </div>
    </v-dialog>
  </v-card>
</template>

<script>
import _ from 'lodash'
import VueTelegramEmbed from 'vue-telegram-embed'
import 'vue-telegram-embed/dist/VueTelegramEmbed.css'
import httpClient from '@/services/http.service'

export default {
  name: 'ShowPosts',
  components: {
    VueTelegramEmbed
  },
  props:{
    fullView:{
      type: Boolean,
      default: false
    },
    product_id:{
      type: Number,
      default: 0
    },
    strictUrl: {
      type: Boolean,
      default: false
    },
    channel_id: {
      type: Number,
      default: 0
    },
    url: {
      type: String,
      default: ''
    },
    isModal:{
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    },
    initPosts:{
      type: Array,
    }
  },
  data(){
    return{
      dialog: false,
      tgLink: null,
      headers:[
        {
          text: 'Дата публикации',
          value: 'posted_at'
        },
        {
          text: 'Шаблон',
          value: 'product.template_title'
        },
        {
          text: 'Доход',
          value: 'earnings'
        },
        {
          text: 'Индекс',
          value: 'index'
        }
      ],
      posts:[],
      loading: false,
    }
  },
  computed:{
    channels(){
      return JSON.parse(JSON.stringify(this.$store.state.channels))
    }
  },
  created() {
    // this.loadPosts()
  },
  mounted() {
    if (this.fullView){
      this.headers.push({text: 'Канал', value: 'channel_id'})
    }
    if (this.initPosts.length === 0){
      this.loadPosts()
    }else{
      this.posts = this.initPosts
    }
  },
  methods:{
    loadPosts(){
      this.loading = true
      httpClient.get(`posts/stats?product_url=${this.url}&channel_id=${this.channel_id}&strict=${this.strictUrl}&product_id=${this.product_id}&search=${this.search}`).then(({data})=>{
        this.posts = data
        this.loading = false
      })
    },
    getChannelName(id){
      if (this.channels.length>0){
        return this.channels.find(x=>x.id===id).name
      }
      return null
    },
    showTgMessage(){
      // const {tgMessage} = this.$refs
      // const tgScript = document.createElement('script')
      // //  <script async src="https://telegram.org/js/telegram-widget.js?15" data-telegram-post="test_ufa/327" data-width="100%">
      // tgScript.setAttribute('src', 'https://telegram.org/js/telegram-widget.js?15')
      // tgScript.setAttribute('data-telegram-post', 'test_ufa/327')
      // tgScript.setAttribute('data-width', '100%')
      // tgScript.addEventListener('load', () => {
      //   const options = {}
      //   new window.Telegram.Embed(tgMessage, options)
      // }, {once: true})
      // document.body.appendChild(tgScript)
      this.dialog = true
    },
    clean(){
      this.posts = []
    }
  }
}
</script>

<style scoped>

</style>
