<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        small
        v-bind="attrs"
        v-on="on"
      >
        +
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(cat, i) in categories"
        :key="i"
        :input-value="true"
        :color="colors[cat.class].base"
        @click="$emit('addCat', prod_id, cat.id)"
      >
        <v-list-item-title >{{ cat.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import colors from 'vuetify/lib/util/colors'

export default {
  name: 'SelectStatCat',
  props:{
    prod_id: Number
  },
  data(){
    return{
      colors,
      categories: JSON.parse(JSON.stringify(this.$store.state.productStatCats))
    }
  }
}
</script>

<style scoped>

</style>
